<template>
  <div>
    <TTableAdvance
      :items="list"
      :fields="fields"
      store="helper.post_categories"
      resource=""
      creatable
      @click-create="showCreateModal = true"
      class="mx-auto"
      style="max-width: 800px"
      noFilter
    >
      <template #append-actions="{ item }">
        <CCol class="d-flex px-2" col="12">
          <TButtonEdit @click="editItem(item)" />
          <TButtonDelete @click="removeCategory(item.id)" />
        </CCol>
      </template>

      <template #name="{ item }">
        <td>
          <TMessageText :value="item.name" />
        </td>
      </template>
    </TTableAdvance>
    <PostCategoryModal :show.sync="showCreateModal" />
    <PostCategoryModal :category="detail" :show.sync="showEditModal" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PostCategoryModal from "./PostCategoryModal.vue";

export default {
  components: { PostCategoryModal },
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 40px" },
        { key: "id", label: "Id", style: "min-width: 300px" },
        { key: "name", label: "Name", _style: "width: 60%" },
      ],
      filter: {},
      showCreateModal: false,
      showEditModal: false,
    };
  },
  created() {
    this.$store.dispatch("helper.post_categories.fetch.if-first-time");
  },
  computed: {
    ...mapGetters({
      list: "helper.post_categories.list",
      detail: "helper.post_categories.detail",
    }),
  },
  methods: {
    removeCategory(id) {
      this.$store.dispatch("helper.post_categories.delete", id);
    },
    editItem(item) {
      this.$store.commit("helper.post_categories.select", item.id);
      this.showEditModal = true;
    },
  },
};
</script>
