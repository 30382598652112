<template>
  <TModal
    :title="is_edit ? 'Post category' : 'Create category'"
    :show="show"
    @update:show="$emit('update:show', $event)"
    @click-create="create()"
    :creating="loading"
  >
    <template #actions>
      <TButtonCreate
        :options="{ disabled: loading }"
        variant="outline"
        @click="create"
        v-if="!is_edit"
      />
      <TButtonSave
        variant="outline"
        @click="update"
        v-if="is_edit"
        :options="{ disabled: loading }"
      />
    </template>
    <TInputText
      label="Id"
      class="mb-3"
      @update:value="input.id = $event"
      :value="input.id"
      :disabled="is_edit"
    />
    <TInputText
      label="Name"
      class="mb-3"
      :value="input.name"
      @update:value="input.name = $event"
    />
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    category: {
      type: Object,
      require: false,
    },
  },
  inputDefault: {
    id: "",
    name: "",
  },
  data() {
    return {
      loading: false,
      data: this.getInput(),
    };
  },
  watch: {
    category() {
      this.data = this.getInput();
    },
  },
  computed: {
    is_edit() {
      if (!this.category) {
        return false;
      }
      return !!this.category.id;
    },
    input: {
      get: function () {
        return this.data;
      },
      set: function (newValue) {
        return (this.data = newValue);
      },
    },
  },
  methods: {
    create() {
      this.loading = true;
      this.$store
        .dispatch("helper.post_categories.create", this.input)
        .then((data) => {
          this.input = this.inputDefault();
          this.$emit("update:show", false);
          this.$emit("updated", data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      this.$store
        .dispatch("helper.post_categories.detail.update", {
          name: this.input.name,
        })
        .then((data) => {
          this.$emit("update:show", false);
          this.$emit("updated", data);
        });
    },
    inputDefault() {
      return { ...this.$options.inputDefault };
    },
    getInput() {
      if (!this.is_edit) {
        return this.inputDefault();
      }
      return { ...this.category };
    },
  },
};
</script>
